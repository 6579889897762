@import './../colors.scss';
.bsx-account-selector {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(23, 27, 34, .7);
    z-index: 9999;

    .bsx-account-selector-modal {
        width: 400px;
        max-height: 600px;
        position: relative;
        margin: 0 auto;
        top: 20vh;
        background: $black;
        color: $white;
        border-radius: 16px;
        padding: 24px;
        word-break: break-all;
        overflow: scroll;

        .title {
            color: $green;
            text-transform: uppercase;
            text-align: center;
            font-size: 24px;
            padding-bottom: 16px;
        }

        .no-account {
            text-align: center;
            padding-top: 24px;
            .name {
                font-size: 18px;
            }
        }

        .account {
            padding: 12px 0px;
            cursor: pointer;
            &:hover {
                color: $green;
            }
        }

        .name {
            font-size: 24px;
            margin-bottom: 0;
        }

        .address {
            font-size: 16px;
            margin-bottom: 0;
        }

        .bsx-loading-accounts {
            text-align:center;
            width: 100%;
            padding: 12px 24px;
        }
    }
}