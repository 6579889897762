$red: #ff5033;
$orange: #ff8133;
$yellow: #ffe733;
$green: #90ff33;
$grayDarkest: #212833;
$grayDarker: #2f4858;
$gray: #576f7c;
$grayLighter: #8ea3ac;
$black: #171b22;
$white: #ebebeb;
$faintGreen: rgba(144, 255, 51, .5);