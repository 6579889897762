@import './../colors.scss';

.bsx-contribute-form {
    padding: 1.5em;
    padding-bottom: 2em;

    label {
        text-transform: uppercase;
        margin-bottom: 4px;
    }
    input {
        background: $black;
        outline: none;
        border: none;
        border-radius: 4px;
        margin-bottom: 1em;
        color: $green;
        text-align: right;
        padding: 0.5em 1em;
        width: 100%;

        &::placeholder {
            text-align: center;
        }

        &:first-of-type {
            border: 1px solid $green;
        }
    }

    .bsx-form-wrapper {
        margin: 0 auto;
        display: block;
        width: 100%;
    }

    button {
        width: 100%;
        border: none;
        outline: none;
        background: $green;
        padding: 0.5em 0.8em;
        padding-top: 0.6em;
        border-radius: 0.8em;
        color: $black;
        text-transform: uppercase;
        font-size: 1.2em;
        margin-top: 0.5em;
        cursor: pointer;
        border: 2px solid $green;

        transition: all 150ms;

        &:hover {
            background: lighten($color: $green, $amount: 15)
        }

        &:disabled {
            opacity: .5;
            cursor: not-allowed;
            border: 2px solid $green;

            &:hover {
                background: $green;
            }
        }
    }

    .contribution-status {
        margin-top: 1.5em;
        text-align: center;
        font-size: 0.9em;
    }
}