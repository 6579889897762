@import './../colors.scss';


.bsx-dashboard {
    background: $grayDarkest;
    font-size: 0.9em;
    -webkit-font-smoothing: antialiased;
}

.bsx-navbar {
    width: 100%;
    height: 4em;
    background-color: $grayDarker;
    padding: 1em;

    @media (max-width: 800px) { 
        height: auto;
    }
}

.bsx-logo {
    color: $green;
    font-size: 2em;
    line-height: 1em;
}

.bsx-menu {
    display: flex;
    justify-content: space-between;
    position: relative;

    @media (max-width: 800px) { 
        height: auto;
    }
}

.bsx-menu-item {
    display: flex;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 1.1em;
    line-height: 2em;

    @media (max-width: 800px) { 
        padding: 0.4em;
    }
    
    a {
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 175ms;
        position: relative;

        &:hover {
            color: $green;
        }    
    }
}

.bsx-menu-col {
    position: relative;
    @media (max-width: 800px) { 
        display: block;
        width: 100%;
    }
}

.bsx-eye {
    position: relative;
    left: 0;
    img {
        width: 6em;
        position: relative
    }

    @media (max-width: 800px) { 
        display: none;
    }
}

$borderStyle: 2px solid $grayDarker;
$borderRadius: 8px;

.bsx-disclaimer {
    color: $grayLighter;
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    margin-top: 100px;
    font-size: 0.9em;

    a, .highlight {
        color: $grayLighter;
        
    }
}

.bsx-account {

    padding-bottom: 1.5em;

    .container-xl {
        margin-top: 4em;
        color: $grayLighter;
        border: $borderStyle;
        border-radius: $borderRadius;

        background-color:rgba(23, 27, 34, 0.5);

        @media (max-width: 800px) { 
            margin-top: 1.5em;
        }
    }

    .bsx-account-selector-display {
        border-bottom: $borderStyle;
    }

    .bsx-chronicle {
        text-align: left;
        text-transform: uppercase;
        color: $grayLighter;
    }

    .bsx-address {
        overflow: hidden;
        border-right: $borderStyle;
        text-align: right;
        color: $green;
        padding: 1em;
        padding-right: 2em;
    }

    .bsx-select-account {
        background: $grayDarker;
        color: $white;
        text-align: center;
        padding: 1em;
        user-select: none;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
            border-top-right-radius: 8px;
            background: $green;
            color: $grayDarkest;
        }
    }

    .bsx-stats {

        .bsx-stat {
            padding: 0.5em;
            padding-top: 0.7em;
            text-align: center;
            text-transform: uppercase;
            font-size: 0.9em;
            border-right: $borderStyle;

        }

        .bsx-stat-title {
            display: block;
        }
        .bsx-stat-value {
            display: block;
        }
        .bsx-stat-balance {
            border-right: none;
            color: $green;
        }
        .bsx-stat-border-right-none {
            border-right: none;
        }
    }
}

.bsx-graph {
    padding-left: 0;
    padding-right: 0;
    width: 0px;
    flex: 1 1 auto;
    z-index: 1;

    @media (max-width: 800px) { 
        width: 100%;
        padding-right: 0;
        margin-bottom: 2.5em;
    }
    
    .bsx-graph-wrapper {
        border: $borderStyle;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        border-bottom: none;
        position: relative;
        left: 0;
        width: 100%;
        height: 500px;
        background-color: rgba(13, 17, 24, 0.5);

        canvas {
            opacity: 0.7;
            z-index: -1
        }

        .bsx-annotation-container {
            position: absolute;
            width: 100%;
            height: 0px;
        }

        .bsx-graph-loader {
            color: $grayDarker;
            width: 100%;
            text-align: center;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            font-size: 2em;
            padding: 2em;
            z-index: 1;

            position: absolute;
            color: $white;
        }
        
    }
    .bsx-graph-timeline {
        border: $borderStyle;
        border-top: none;
        width: 100%;
        height: 3.5em;
        color: $white;
        
        line-height: 2.5em;
        font-size: 1em;
        padding: 0.5em 1em;
        padding-top: 10px;
        position: relative;

        background-color: rgba(23, 27, 34, 0.7);

        .bsx-legend {
            text-transform: uppercase;
            text-align: center;
            color: $white;

            .basilisk {
                color: $green;
            }
            .sibling {
                color: $yellow;
            }
        }

        .bsx-progress-bar-container {
            width: 100%;
            height: 10px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            border-top: $borderStyle;
            border-bottom: $borderStyle;
        }

        .bsx-progress-bar {
            background: $grayDarker;
            height: 8px;
            position: relative;
            top: 0;
            left: 0;
        }

        .row {
            
            :first-child {
                z-index: 10;
                text-align: left;

            }
            :last-child {
                z-index: 10;
                text-align: right;
            }
        }
    }
}

.bsx-contribute {
    border: 1px solid $green;
    color: $green;
    background-color: $grayDarkest;
    border-radius: $borderRadius * 2;
    padding: 0;
    margin: 0;
    flex: 0 0 300px;
    width: 0px;
    margin: 2px;
    z-index: 1;
    background-color: rgba(23, 27, 34, 0.4);
    display: none;

    @media (max-width: 800px) { 
        width: 100%;
        margin:auto;
    }

    .bsx-incentives {
        background: $black;
        padding: 1em 1.5em;
        border-top-left-radius: $borderRadius * 2;
        border-top-right-radius: $borderRadius * 2;
        border-bottom: 1px solid $green;
        position: relative;

        .bsx-incentives-loader {
            height: 4em;
            margin: 0.5em 0em;
            position: relative;
            background: $black;
            text-align: center;
            color: $green;
            line-height: 2em;
            text-transform: uppercase;
        }
    }

    .bsx-incentive {
        font-size: 1.1em;
        text-transform: uppercase;
        margin-bottom: 0.5em;
        margin-top: 0.5em;

        .value {
            text-align: right;
        }
    }
}

.bsx-wallpaper {
    margin-top: -500px;
    width: 100%;
    z-index: -1;
    
    img {
        width: 85%;
        position: relative;
        left: -15%;
        max-width: 1500px;
        display: block;

        @media (max-width: 800px) { 
            width: 100%;
            top: 26em;
            min-width: 500px;
            position: absolute;
        }
    }
}

@media (max-width: 1420px) {
    .bsx-contribute .bsx-incentives .bsx-incentives-loader {
        line-height: 2em;
    }
    
  }
  